import axios from 'axios';
import APIUtils from './APIUtils';
import LocaleUtils, { LanguageObject } from './LocaleUtils';

interface Province {
  id: number;
  key: string;
  name: LanguageObject;
}

const province = {
  generateDefault: (item?: Partial<Province>): Partial<Province> => {
    return {
      id: item?.id,
      key: item?.key,
      name: LocaleUtils.langobj.generate(item?.name),
    };
  },

  api: {
    list: async () => {
      const resp = await axios.get<Province[]>('province/list.php');
      return resp.data;
    },
    get: async (id: number | string) => {
      const resp = await axios.get<Province>('province/get.php', {
        params: { id: id },
      });
      return resp.data;
    },
    create: async (item: Partial<Province>) => {
      const resp = await axios.post<Province>('province/create.php', {
        ...APIUtils.clean(item),
      });
      return resp.data;
    },
    update: async (item: Partial<Province>) => {
      const resp = await axios.put<Province>('province/update.php', {
        ...APIUtils.clean(item),
      });
      return resp.data;
    },
    remove: async (id: number | string) => {
      const resp = await axios.delete<Province>('province/remove.php', {
        params: { id: id },
      });
      return resp.data;
    },
  },
};

export default { province };
export { Province };
