import axios from 'axios';
import APIUtils from './APIUtils';
import LocaleUtils, { LanguageObject } from './LocaleUtils';
import ProvinceUtils, { Province } from './ProvinceUtils';

interface LanguageDesignation {
  id: number;
  key: string;
  province: Partial<Province>;
  name: LanguageObject;
  description: LanguageObject;
}

const languageDesignation = {
  generateDefault: (
    item?: Partial<LanguageDesignation>
  ): Partial<LanguageDesignation> => {
    return {
      id: item?.id,
      key: item?.key,
      province: ProvinceUtils.province.generateDefault(item?.province),
      name: LocaleUtils.langobj.generate(item?.name),
      description: LocaleUtils.langobj.generate(item?.description),
    };
  },

  api: {
    list: async (params?: { province_key: string }) => {
      const resp = await axios.get<LanguageDesignation[]>(
        'languageDesignation/list.php',
        { params: params }
      );
      return resp.data;
    },
    get: async (id: number | string) => {
      const resp = await axios.get<LanguageDesignation>(
        'languageDesignation/get.php',
        {
          params: { id: id },
        }
      );
      return resp.data;
    },
    create: async (item: Partial<LanguageDesignation>) => {
      const resp = await axios.post<LanguageDesignation>(
        'languageDesignation/create.php',
        {
          ...APIUtils.clean(item),
        }
      );
      return resp.data;
    },
    update: async (item: Partial<LanguageDesignation>) => {
      const resp = await axios.put<LanguageDesignation>(
        'languageDesignation/update.php',
        {
          ...APIUtils.clean(item),
        }
      );
      return resp.data;
    },
    remove: async (id: number | string) => {
      const resp = await axios.delete<LanguageDesignation>(
        'languageDesignation/remove.php',
        {
          params: { id: id },
        }
      );
      return resp.data;
    },
  },
};

export default { languageDesignation };
export { LanguageDesignation };
