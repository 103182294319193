






import PointOfServiceUtils from '@/utils/PointOfServiceUtils';
import Vue from 'vue';

export default Vue.extend({
  methods: {
    async handleGenerateDataSet() {
      try {
        await PointOfServiceUtils.api.generateSearchDataset();
        this.$toast.push({
          text: this.$t('success.create', [this.$tc('dataset.label')]),
          type: 'success',
        });
      } catch (error) {
        this.$toast.push({
          text: this.$t('error.create', [this.$tc('dataset.label')]),
          type: 'error',
        });
      } finally {
        //
      }
    },
  },
});
